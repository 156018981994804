import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { NewSelect } from './Select';

const rowOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const Pagination = <T extends FieldValues>({
  control,
  limit,
  name,
  totalPages,
  currentPage,
  count,
}: {
  control: Control<T>;
  limit: number;
  name: Path<T>;
  totalPages: number;
  currentPage: number;
  count: number;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => {
        const startRow = (currentPage - 1) * limit + 1;
        const endRow = Math.min(currentPage * limit, count);

        return (
          <div className="flex flex-col items-end md:flex-row md:justify-between">
            <div className="flex items-center">
              <p>Rows per page:</p>
              <NewSelect
                control={control}
                id="limit"
                name={'limit' as Path<T>}
                options={rowOptions}
                className="border-none font-bold"
                noBackground={true}
                isSearchable={false}
              />
              <span className="mx-1" id={`pagination-${startRow}-start-row`}>
                {startRow}
              </span>
              -
              <span className="mx-1" id={`pagination-${endRow}-end-row`}>
                {endRow}
              </span>
              of {count}
              <button
                type="button"
                id="pagination-previous"
                className={`text-color cursor-pointer rounded p-2 ${
                  currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
                }`}
                onClick={() => onChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <MdOutlineKeyboardArrowLeft size={20} />
              </button>
              <button
                type="button"
                id="pagination-next"
                className={`text-color cursor-pointer rounded p-2 ${
                  currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
                }`}
                onClick={() => onChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <MdOutlineKeyboardArrowRight size={20} />
              </button>
            </div>
          </div>
        );
      }}
    />
  );
};
export default Pagination;
