import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdFilterList } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { Accordion, IconButton } from '@epcbuilder/lib/components';
import Pagination from '@epcbuilder/lib/components/Pagination';
import useProperties from '@/hooks/properties/useProperties';
import { Filters } from '@/models/properties';
import FiltersForm from './Filters';
import PropertiesHeader from './PropertiesHeader';
import PropertiesTable from './PropertiesTable';

const Properties = () => {
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<boolean>(true);

  const defaultValues = {
    search: '',
    page: 1,
    limit: 10,
    currentEpcFilters: [],
    potentialEpcFilters: [],
    jobStatusFilters: [],
    showInactive: false,
    showEligibleOnly: false,
  };

  const {
    register,
    control,
    watch,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<Filters>({ defaultValues });
  const values = watch([
    'search',
    'page',
    'limit',
    'currentEpcFilters',
    'potentialEpcFilters',
    'jobStatusFilters',
    'showInactive',
    'showEligibleOnly',
  ]);
  const {
    search,
    page,
    limit,
    currentEpcFilters,
    potentialEpcFilters,
    jobStatusFilters,
    showInactive,
    showEligibleOnly,
  } = getValues();
  const { properties, count, totalPages, mutate, isLoading } = useProperties({
    search,
    page,
    limit,
    currentEpcFilters,
    potentialEpcFilters,
    jobStatusFilters,
    showInactive,
    showEligibleOnly,
  });

  useEffect(() => {
    handleSubmit(() => mutate());
  }, [values, mutate, handleSubmit]);

  useEffect(() => {
    if (
      search ||
      currentEpcFilters ||
      potentialEpcFilters ||
      jobStatusFilters ||
      showInactive ||
      showEligibleOnly ||
      limit
    )
      setValue('page', 1);
  }, [
    currentEpcFilters,
    jobStatusFilters,
    potentialEpcFilters,
    search,
    showInactive,
    showEligibleOnly,
    limit,
    setValue,
  ]);

  useEffect(() => {
    const search = searchParams.get('search');
    if (!search) return;

    setFilters(true);
    setValue('search', search);
  }, [searchParams, setValue]);

  return (
    <>
      <Accordion
        accordionOpen={filters}
        accordionChildren={
          <FiltersForm control={control} register={register} errors={errors} reset={() => reset(defaultValues)} />
        }
      >
        <div className="flex w-full flex-row items-center justify-between">
          <h1 className="text-2xl">Properties</h1>
          <div className="flex flex-1 flex-row items-center justify-end gap-4">
            <IconButton id="filters-button" style="secondary" onClick={() => setFilters(!filters)}>
              <MdFilterList size={20} />
            </IconButton>
          </div>
        </div>
      </Accordion>
      <PropertiesHeader />
      <PropertiesTable count={count} isLoading={isLoading} properties={properties} />
      <div className="items-left justify-left flex pb-6">
        {count && totalPages > 1 && (
          <div className="justify-left flex items-end pt-6">
            <Pagination
              control={control}
              name="page"
              totalPages={totalPages}
              currentPage={page}
              count={count}
              limit={limit}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Properties;
